<template>
  <div id="app" class="flex flex-col mx-auto px-0  max-w-md">
    <img class="mx-auto h-16 my-3 w-auto" src="../assets/camwater.jpg" alt="camwater"/>
    <p class="text-white w-full bg-neutral-500 text-sm text-center py-1">
      Votre Facture Camwater à porter de main
    </p>
    <div v-if="isSuccess" class="flex flex-col justify-center items-center py-16 px-8">
      <svg xmlns="http://www.w3.org/2000/svg" style="color: #10b981" class="w-16 h-16" fill="none" viewBox="0 0 24 24"
           stroke="currentColor" stroke-width="1">
        <path stroke-linecap="round" stroke-linejoin="round" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"/>
      </svg>

      <p class="text-gray-500 text-md text-center mx-auto">
        Vous allez recevoir un SMS avec le lien de votre facture que vous pourrez télécharger.
      </p>
    </div>
    <div v-else>
      <form class="bg-white shadow-md m-4"  @submit.prevent="submitForm">
          <label class="block text-sm mb-2 float-left" style="color: #1f497d" for="phone">
            Numéro de téléphone
          </label>
          <input v-model="form.phone"
                 class="appearance-none border rounded w-full py-1 px-2
             leading-tight focus:outline-none focus:shadow-outline" style="background-color: #e5e7eb"
                 id="phone" minlength="8"
                 type="text">
          <label class="block text-sm mb-2 float-left" style="color: #1f497d" for="reference">
            Numéro de reference
          </label>
          <input v-model="form.reference"
                 class="appearance-none border rounded w-full py-1 px-2
             leading-tight focus:outline-none focus:shadow-outline" style="background-color: #f4f4f4"
                 id="reference"
                 type="text">
        <div v-if="!isLoading" class="w-full mt-2 text-center">
          <button type="submit"
                  class="text-white bg-blue-600 focus:ring-0 form-medium rounded mb-4 text-sm px-5 py-2.5"
                  style="background-color: #1f497d">
            Envoyer
          </button>
        </div>
        <LoaderComponent class="mt-3" v-else/>
      </form>
    </div>
  </div>
</template>

<script>

import axios from "axios";
import '../assets/radio.css'
import LoaderComponent from "@/components/LoaderComponent.vue";

export default {
  components: {LoaderComponent},
  data() {
    return {
      isSuccess: false,
      isNotVerified: false,
      isLoading: false,
      showInput: false,
      formIsValid: true,
      form: {
        phone: '',
        reference: '',
      }
    }
  },
  methods: {
    submitForm(event) {
      // prevent default form submission behavior
      event.preventDefault();
      console.log(this.form)
      if (this.form.phone !== '' && this.form.reference !== '') {
        this.isLoading = true
        axios.post(`https://ms-camwater-preprod.nexah.net/api/v1/sendInvoice`, this.form).then(() => {
          this.isLoading = false
          this.isSuccess = true
        }).catch(() => {
          this.isLoading = true
        })
      } else {
        this.formIsValid = false;
      }
    },
  }
}

</script>